
import { Component, Vue } from 'vue-property-decorator'
import { Page, Table } from '../../types/common'
import { ApplyData } from '../../types/experienceApply'
import { KeepAlive } from '@/utils/decorators'
import ManagerDialog from './ManagerDialog.vue'
import Examine from './Examine.vue'
import Enter from './Enter.vue'

@Component({
  name: 'applyList',
  components: { ManagerDialog, Examine, Enter },
  filters: {
    isStatusFilters (value: string) {
      const map = new Map([
        ['1', '待审核'],
        ['2', '待录入'],
        ['3', '已关闭'],
        ['4', '已完成']
      ])
      return map.get(value) ? map.get(value) : ''
    }
  }
})
@KeepAlive
export default class extends Vue {
  private dateRange = []
  private searchInfo = {
    applyName: '',
    companyName: '',
    source: '',
    status: '',
    startTime: '',
    endTime: ''
  }

  private tableData: Table = {
    loading: false,
    tr: [
      {
        label: '客户姓名',
        prop: 'applyName',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '联系电话',
        prop: 'phone',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '客户邮箱',
        prop: 'email',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '公司名称',
        prop: 'companyName',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '客户来源',
        prop: 'source',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '客户经理名称',
        prop: 'smName',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '系统版本',
        prop: 'versionName',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '系统账号',
        prop: 'adminAccount',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '体验截止日期',
        prop: 'validDate',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '剩余体验天数',
        prop: 'surplusDay',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '状态',
        prop: 'status',
        minWidth: '60',
        showOverflowTooltip: true
      },
      {
        label: '最后操作人',
        prop: 'processUserName',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '最后操作时间',
        prop: 'utime',
        minWidth: '120',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private managerShow = false
  private examineShow = false
  private enterShow = false
  private applyId = ''

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get<Page<ApplyData>>(this.$apis.experienceApply.selectTenantApplyByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.tableData.data = res.list || []
        this.handleDay()
        this.total = res.total || 0
        this.tableData.loading = false
      })
      .catch((err) => {
        this.tableData.loading = false
        console.log(err)
      })
  }

  handleDay () {
    this.tableData.data.forEach((item: any) => {
      if (item.validDate) {
        const day1 = new Date(item.validDate).getTime()
        const nowDay = this.getNowFormatDate()
        const day2 = new Date(nowDay).getTime()
        console.log(day2)
        let day3 = (day1 - day2) / (1000 * 60 * 60 * 24)
        day3 = day3 > 0 ? day3 : 0
        item.surplusDay = Math.trunc(day3).toString() + '天'
      } else {
        item.surplusDay = ''
      }
    })
  }

  getNowFormatDate () {
    const date = new Date()
    const seperator1 = '-'
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const strDate = date.getDate()
    const currentdate = year + seperator1 + month + seperator1 + strDate
    return currentdate
  }

  dateRangeChange (date: any) {
    if (date) {
      this.searchInfo.startTime = date[0]
      this.searchInfo.endTime = date[1]
    } else {
      this.searchInfo.startTime = ''
      this.searchInfo.endTime = ''
    }
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 经理配置关闭
  closeAccountManager () {
    this.managerShow = false
    this.getData()
  }

  // 审核
  onExamine (applyId: string) {
    this.applyId = applyId
    this.examineShow = true
  }

  // 审核关闭
  closeExamine () {
    this.examineShow = false
    this.getData()
  }

  // 录入
  onEnter (applyId: string) {
    this.applyId = applyId
    this.enterShow = true
  }

  // 录入关闭
  closeEnter () {
    this.enterShow = false
    this.getData()
  }

  onAdded () {
    this.$router.push({
      name: 'applyAdd'
    })
  }

  // 查看
  onDetail (id: string) {
    this.$router.push({
      name: 'applyDetail',
      params: { id }
    })
  }

  // 编辑
  onUpdate (id: string) {
    this.$router.push({
      name: 'applyEdit',
      params: { id }
    })
  }
}
