
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Managers, MangerItem, Areas, Position } from '../../types/experienceApply'

@Component
export default class ManagerDialog extends Vue {
  @Prop() readonly managerShow!: boolean
  private accountManagerList: Managers[] = []
  private tagLoading = false

  private oldData: Managers = {
    smId: '',
    name: '',
    phone: '',
    tenantSmAreaList: []
  }

  private isEdit = false
  private options: Array<Position> = []
  private tenantSmAreaList: Array<Areas> = []
  private cascaderList: Array<any> = []

  created () {
    this.getAccountManagerList()
    this.getRegions()
  }

  closeAccountManager () {
    this.$emit('closeAccountManager')
  }

  // 获取客户经理列表
  getAccountManagerList () {
    this.$axios.get(this.$apis.experienceApply.selectSmByList).then(res => {
      res.list.forEach((item: Managers) => {
        if (item.tenantSmAreaList.length > 0) {
          item.listShow = []
          item.tenantSmAreaList.forEach((a: Areas) => {
            item.listShow.push(a.province)
          })
          item.listShow = [...new Set(item.listShow)]
          const list = []
          for (let i = 0; i < item.listShow.length; i++) {
            const arr: Array<any> = []
            item.tenantSmAreaList.forEach((b: Areas) => {
              if (b.province === item.listShow[i] && b.city !== null) {
                arr.push(b.city)
              }
            })
            list.push(
              {
                province: item.listShow[i],
                city: arr
              }
            )
          }
          item.listShow = list
          item.areaName = item.listShow.map((items: Areas) => {
            if (items.city && items.city.length > 0) {
              return items.province + '(' + items.city + ')'
            } else {
              return items.province
            }
          }).join('、')
        }
      })
      this.accountManagerList = res.list || []
    })
  }

  getRegions () {
    this.$axios.get<Position[]>(this.$apis.common.getRegion).then(res => {
      this.options = res || []
      this.options.forEach((item) => {
        if (item.children && item.label === item.children[0].label) {
          item.children = null
        }
        if (item.children) {
          item.children.forEach((items) => {
            if (items.children) {
              items.children = null
            }
          })
        }
      })
    })
  }

  positionChange (value: string[]) {
    this.tenantSmAreaList = []
    value.forEach((item) => {
      this.tenantSmAreaList.push(
        {
          province: item[0],
          city: item.length === 2 ? item[1] : null
        }
      )
    })
  }

  // 新增业务标签
  onAdd () {
    if (!this.isEdit) {
      this.cascaderList = []
      this.accountManagerList.push({
        name: '',
        phone: '',
        tenantSmAreaList: [],
        isInput: true
      })
      this.isEdit = true
    } else {
      this.$message.warning('请先保存正在编辑的配置')
    }
  }

  // 取消业务标签
  onCancel (scope: MangerItem) {
    if (scope.row.smId) {
      // 是否为编辑
      this.accountManagerList[scope.$index].isInput = false
      this.accountManagerList[scope.$index] = this.oldData // 取消值不变
    } else {
      this.accountManagerList.splice(scope.$index, 1)
    }
    this.isEdit = false
  }

  // 保存业务标签
  onSave (scope: any) {
    const row = scope.row
    console.log(row.smId)
    if ((scope.$index !== 0 && row.name && row.phone && this.tenantSmAreaList.length > 0) || (scope.$index === 0 && row.name && row.phone)) {
      if (!/^\d{11}$/.test(row.phone)) {
        this.$message({ type: 'warning', message: '请输入正确手机号码' })
        return
      }
      const info = row.smId
        ? {
          smId: row.smId,
          name: row.name,
          phone: row.phone,
          tenantSmAreaList: this.tenantSmAreaList
        }
        : {
          name: row.name,
          phone: row.phone,
          tenantSmAreaList: this.tenantSmAreaList
        }

      const url = row.smId
        ? this.$apis.experienceApply.updateSm
        : this.$apis.experienceApply.insertSm

      this.$axios.post(url, info).then(() => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.getAccountManagerList()
        this.isEdit = false
      }).catch((error) => {
        console.error(error)
      })
    } else {
      this.$message({
        message: '请输入完整信息',
        type: 'warning'
      })
    }
  }

  // 编辑业务标签
  onEdit (scope: MangerItem) {
    if (!this.isEdit) {
      this.tenantSmAreaList = scope.row.tenantSmAreaList
      this.accountManagerList[scope.$index] = {
        ...scope.row,
        isInput: true
      }
      this.cascaderList = []
      scope.row.tenantSmAreaList.forEach((item) => {
        const arr = []
        arr.push(item.province)
        if (item.city) {
          arr.push(item.city)
        }
        this.cascaderList.push(arr)
      })
      this.oldData = scope.row
      this.$set(this.accountManagerList, scope.$index, this.accountManagerList[scope.$index]) // 重新渲染表格数据
      this.isEdit = true
    } else {
      this.$message.warning('请先保存正在编辑的配置')
    }
  }

  // 删除业务标签
  onDelete (smId: string) {
    if (!this.isEdit) {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.tagLoading = true
          this.$axios.post(this.$apis.experienceApply.deleteDataSm, { smId }).then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getAccountManagerList()
          }).finally(() => {
            this.tagLoading = false
          })
        })
    } else {
      this.$message.warning('请先保存正在编辑的配置')
    }
  }
}
