
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { EnterInfo } from '../../types/experienceApply'

@Component
export default class Enter extends Vue {
  @Prop() readonly enterShow!: boolean;
  @Prop() readonly applyId!: string;
  private info = {
    customerId: '',
    versionName: '',
    validDate: ''
  }

  private roleList: EnterInfo[] = []

  private rules = {
    customerId: [
      { required: true, message: '请选择系统账号名称', trigger: 'change' }
    ]
  }

  private submitShow = false

  created () {
    this.getRoleList()
  }

  // 获取系统账号列表
  getRoleList () {
    this.$axios.get(this.$apis.customer.customerList).then((res) => {
      this.roleList = res.list || []
    })
  }

  changeAccount (value: any) {
    const items = this.roleList.find((item) => item.customerId === value)
    if (items) {
      this.info.customerId = items.customerId
      this.info.versionName = items.versionName
      this.info.validDate = items.validDate
    }
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.experienceApply.recordTenantApply, {
          ...this.info,
          applyId: this.applyId
        }).then(() => {
          this.$message.success('录入完成')
          this.closeEnter()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 关闭
  closeEnter () {
    this.$emit('closeEnter')
    this.info = {
      customerId: '',
      versionName: '',
      validDate: ''
    }
    ;(this.$refs.info as ElForm).clearValidate()
  }
}
