
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class Examine extends Vue {
  @Prop() readonly examineShow!: boolean;
  @Prop() readonly applyId!: string;
  private info = {
    status: '',
    bak: ''
  }

  private rules = {
    status: [
      { required: true, message: '请选择审核结果', trigger: 'change' }
    ]
  }

  private submitShow = false

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        if (this.info.status === '3' && this.info.bak === '') {
          this.$message.warning('请输入备注说明')
        } else {
          this.submitShow = true
          this.$axios.post(this.$apis.experienceApply.auditTenantApply, {
            ...this.info,
            applyId: this.applyId
          }).then(() => {
            this.$message.success('审核完成')
            this.closeExamine()
          }).finally(() => {
            this.submitShow = false
          })
        }
      }
    })
  }

  // 关闭
  closeExamine () {
    this.$emit('closeExamine')
    this.info = {
      status: '',
      bak: ''
    }
    ;(this.$refs.info as ElForm).clearValidate()
  }
}
